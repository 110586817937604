import Vue from 'vue'
import Router from 'vue-router'
import P404 from './components/p404'
import PublicPollResponse from './components/public.poll.response'
import SurveyPage from './components/survey.page.vue'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/pp/:showCode/:pollCode',
      name: 'PublicPollResponse',
      component: PublicPollResponse,
      props: true
    },
    {
        path: '/survey/:showCode/:pollCode',
        name: 'SurveyPage',
        component: SurveyPage,
        props: true
      },
  
    {
      path: '/404',
      name: 'FourOhFour',
      component: P404
    }
  ]
})
