<template>
    <div data-bs-theme="dark"
         class="new-survey-container text-body bg-body">
        <vue-title :title="title" />
        <div class="poll-container justify-content-center flex-column"
            v-show="!submitting && !errorMessage && !pollClosed && !pollSubmitted">

            <div class="container">
                <div v-if="currentQuestion && currentQuestion.QuestionType == 'TextPrompt'">
                    <markdown-output :content="currentQuestion.Name">
                    </markdown-output>
                </div>
                <div v-else-if="currentQuestion">
                    <h2>
                        {{ currentQuestion.Name }}<span v-if="currentQuestion.IsMandatory">*</span>
                    </h2>
                    <p v-if="currentQuestion.Notes"
                    class="mb-2">
                        {{ currentQuestion.Notes }}
                    </p>
                </div>

                <div v-if="currentQuestion && currentQuestion.QuestionType == 'SingleLineText'">
                    <input v-model="currentQuestion.Response"
                        :placeholder="currentQuestion.Name"
                        autofocus
                        ref="textInput"
                        @keyup="onKeyUp"

                        class="form-control form-control-lg bg-dark">
                </div>
                <div v-else-if="currentQuestion && currentQuestion.QuestionType == 'FreeformText'">
                    <textarea v-model="currentQuestion.Response" class="form-control form-control-lg bg-dark"
                            rows="3"
                            ref="textInput"
                            autofocus>
                    </textarea>
                </div>
                <div v-else-if="currentQuestion && currentQuestion.QuestionType == 'MultipleChoice'">
                    <div v-for="(answer, ix) in currentQuestion.Answers"
                        :key="answer.Response"
                        class="form-check">
                        <input class="form-check-input bg-dark"
                                type="radio"
                                :name="currentQuestion.Code"
                                :id="`${currentQuestion.Code}${ix}`"
                                :ref="`${ix == 0 ? 'textInput' : `_${ix}`}`"
                                v-model="currentQuestion.Response"
                                :value="answer.Response"
                                @keyup="onKeyUp">
                        <label class="form-check-label"
                            :for="`${currentQuestion.Code}${ix}`">
                            {{ answer.DisplayText }}
                        </label> 
                    </div>
                </div>

                <div class="alert alert-danger mt-2" v-if="mandatoryError">
                    {{ mandatoryErrorMessage }}
                </div>

                <div class="text-center mt-2">
                    <button @click="navigateQuestion(-1)"
                            class="btn btn-outline-primary btn-lg m-2"
                            :disabled="currentQuestionIndex == -1">
                        <i class="bi bi-caret-left-fill"></i>                   
                    </button>
                    <button @click="navigateQuestion(1)"
                            class="btn btn-outline-primary btn-lg m-2">
                        <i class="bi bi-caret-right-fill"
                            v-if="!atEndOfPoll"></i>
                        <span v-else>
                            Submit
                        </span>
                    </button>
                </div>

            </div>
        </div>
        <div class="loading-container d-flex align-items-center justify-content-center flex-column bg-dark-subtle"
            :class="{'fade-out': !submitting}">
            <div class="spinner-border text-info"
                style="width: 3rem; height: 3rem;"
                role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            <h4 class="mt-2">{{ submittingMessage }}</h4>
        </div>
        <div class="error-container container justify-content-center flex-column"
            v-if="errorMessage">
            {{ errorMessage }}
        </div>
        <div class="closed-container container justify-content-center flex-column"
            v-if="pollClosed">
            <markdown-output :content="poll.ClosedMessage">
            </markdown-output>
        </div>
        <div class="thank-you-container container justify-content-center flex-column"
            v-if="pollSubmitted">
            <markdown-output :content="poll.ConclusionMessage">
            </markdown-output>
        </div>

        <footer>
            &copy; <a href="https://www.tractusevents.com" target="_blank">Tractus Events</a> | <a href="https://www.tractusevents.com/privacy" target="_blank">Privacy Policy</a>
        </footer>
    </div>
</template>
<script>
import MarkdownOutput from './markdown.view.vue'
import Vue from 'vue'

export default {
    props: ['showCode', 'pollCode'],

    components: {
        MarkdownOutput
    },

    data() {
        return {
            poll: null,
            submitting: false,
            pollClosed: false,
            errorMessage: null,
            pollSubmitted: false,

            mandatoryError: false,
            mandatoryErrorMessage: '',

            submittingMessage: 'Loading, please wait...',

            currentQuestionIndex: -1,
        }
    },

    watch: {
        showCode() {
            this.build();
        },

        pollCode() {
            this.build();
        }
    },

    computed: {
        title: function () {
            if (this.poll) {
                return this.poll.Name + " | Tractus Events";
            } 

            return "Loading... | Tractus Events";
        },

        currentQuestion() {
            if(!this.poll) {
                return null;
            } 

            if(this.currentQuestionIndex == -1) {
                return {
                    Name: this.poll.HeaderText,
                    QuestionType: 'TextPrompt',
                };
            }

            return this.poll.Questions[this.currentQuestionIndex];
        },

        atEndOfPoll() {
            if(!this.poll) {
                return false;
            }

            return this.currentQuestionIndex == this.poll.Questions.length -1;
        }
    },

    methods: {
        onKeyUp(e) {
            if(e.key == 'Enter') {
                this.navigateQuestion(1);
            }
        },

        navigateQuestion(step) {
            this.mandatoryError = false;
            this.mandatoryErrorMessage = null;

            if(this.currentQuestion.IsMandatory 
                && !this.currentQuestion.Response
                && step == 1) {
                this.mandatoryErrorMessage = "This question is mandatory.";
                this.mandatoryError = true;
                this.focusInput();
                return;
            }

            if(this.currentQuestionIndex == -1 && step == -1) {
                return;
            } else if(this.atEndOfPoll && step == 1) {
                // Get ready to submit.
                this.submitResponse();
                return;
            }


            this.currentQuestionIndex += step;

            this.focusInput();
        },

        focusInput() {
            Vue.nextTick(() => {
                if(!this.$refs.textInput) {
                    return;
                }

                if(this.$refs.textInput.length) {
                    this.$refs.textInput[0].focus();
                } else {
                    this.$refs.textInput.focus();
                }

            });            
        },

        async build() {
            this.submitting = true;
            this.pollClosed = false;
            this.errorMessage = null;
            this.pollSubmitted = false;
            this.submittingMessage = "Loading, please wait...";

            this.poll = null;
            this.currentQuestionIndex = -1;
            const pollUrl = `${process.env.VUE_APP_API_URL}/api/poll/public/${this.showCode}/${this.pollCode}`;

            try {

                let result = await fetch(pollUrl, { mode: 'cors'});

                let pollResult = await result.json();

                this.poll = pollResult.Result.Poll;

                if (pollResult.ErrorCodes.length > 0
                    && pollResult.ErrorCodes[0] == 'ERR_POLL_CLOSED') {
                    this.pollClosed = true;
                } else if (pollResult.ErrorCodes.length > 0) {
                    this.error = pollResult.Errors[0];
                } 

            } catch(ex) {
                console.error(ex);
            }

            //setTimeout(() => this.submitting = false, 2000);

            this.submitting = false;
        },

        async submitResponse() {
            this.submitting = true;
            this.submittingMessage = "Sending your response, please wait...";
            this.mandatoryError = null;
            this.mandatoryErrorMessage = null;

            try {
                this.poll.Id = 0;
                this.poll.ShowCode = this.showCode;

                let toPost = new FormData();
                toPost.append("poll", JSON.stringify(this.poll));

                let postUrl = `${process.env.VUE_APP_API_URL}/api/poll/public`

                let r = await fetch(postUrl, {
                    mode: "cors",
                    method: "post",
                    headers: {
                        Accept: "application/json",
                    },
                    body: toPost,
                });

                r = await r.json();

                if(r.Errors.length > 0) {
                    this.mandatoryError = true;
                    this.mandatoryErrorMessage = r.Errors[0];
                } else {
                    this.pollSubmitted = true;
                }

            } catch(ex) {
                this.mandatoryError = true;
                this.mandatoryErrorMessage = "We could not save your response. Please try again later.";
            }

            this.submitting = false;
        }

    },

    mounted() {
        this.build();
    }
}
</script>